import React, { useState } from "react";
import SubHeader from "../../components/SubHeader";
import OurMission from "./OurMission";
import OurStory from "./OurStory";
import OurUsp from "./OurUsp";
import OurVision from "./OurVision";
import Sidebar from "./Sidebar";

const Aboutpage = () => {
  const [curruntPath, setCurruntPath] = useState("Our Mission");

  const menuItems = [
    { name: "Our Mission" },
    { name: "Our Vision" },
    { name: "Our Story" },
    { name: "Our Usp" },
  ];

  return (
    <>
      <div className="rightsidebar">
        <div className="labora_page_loader" style={{ display: "none" }} />
        <div id="wrapper">
          <SubHeader subHeaderText={curruntPath} />
          <div id="main">
            <div id="primary" className="pagemid">
              <div className="inner">
                <main className="content-area">
                  {curruntPath === "Our Mission" && <OurMission />}
                  {curruntPath === "Our Vision" && <OurVision />}
                  {curruntPath === "Our Story" && <OurStory />}
                  {curruntPath === "Our Usp" && <OurUsp />}
                </main>
                <Sidebar
                  setCurruntPath={setCurruntPath}
                  menuItems={menuItems}
                  initName={"Our Mission"}
                />
              </div>
            </div>
            <div className="about-content">
              <h3 className="about-heading">
                At Nexi Sphere, we redefine global trade by bridging markets
                with precision, trust, and innovation.
              </h3>
              <p className="about-paragraph">
                As a dynamic import-export enterprise, we specialize in
                connecting diverse markets, ensuring that quality products reach
                their destination efficiently and reliably. Founded on the
                principles of integrity and excellence, Nexi Sphere partners
                with businesses worldwide, facilitating seamless international
                transactions.
              </p>
              <p className="about-paragraph">
                Our dedicated team leverages extensive industry expertise and a
                forward-thinking approach to meet the ever-evolving demands of
                global commerce. We don’t just trade goods—we build enduring
                relationships and foster growth across borders. Our mission is
                to empower our partners by providing access to global
                opportunities, helping them thrive in an interconnected world.
              </p>
              <p className="about-paragraph">
                Join us on this journey toward limitless possibilities and a
                future of shared success.
              </p>
            </div>
          </div>
          <div className="banana-container">
            <h2 className="section-title">
              Why Choose Nexi Sphere for Natural Powder Trading?
            </h2>
            <div className="features">
              <ul>
                <li>
                  🌟 <strong>Extensive product range</strong> for various
                  industries.
                </li>
                <li>
                  🔗 <strong>Reliable supplier network</strong> for consistent
                  quality and availability.
                </li>
                <li>
                  💰 <strong>Competitive pricing</strong> and customizable
                  packaging options.
                </li>
              </ul>
            </div>

            <h2 className="section-title">
              Industries Using Natural Powder Products
            </h2>
            <div className="industries">
              <div className="industry">
                <h3>🍽️ Food & Beverage Industry:</h3>
                <p>
                  Our natural products are a great addition to baby food, health
                  drinks, smoothies, and baked goods, enhancing both taste and
                  nutrition with pure, high-quality ingredients.
                </p>
              </div>
              <div className="industry">
                <h3>🏋️ Health & Wellness:</h3>
                <p>
                  Packed with essential nutrients, our natural products are
                  perfect for nutritional supplements, protein bars, and
                  gluten-free foods, helping to support a healthy, balanced
                  lifestyle.
                </p>
              </div>
              <div className="industry">
                <h3>💄 Cosmetics & Personal Care:</h3>
                <p>
                  Our natural products are ideal for use in skincare items such
                  as exfoliants, face masks, and hair care treatments, offering
                  soothing and antioxidant properties.
                </p>
              </div>
              <div className="industry">
                <h3>🐾 Pet Food Industry:</h3>
                <p>
                  Increasingly used as a wholesome ingredient, our natural
                  products are perfect for dog and cat treats, providing
                  digestibility and essential nutrients for pets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutpage;
