import React from "react";
import SubHeader from "../components/SubHeader";

const InstantBananaPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="Instant Banana Powder" />
      <div className="products-container">
        <div className="products-text">
          <h2>Description</h2>
          <p>
            Designed for quick and easy mixing, this instant banana powder
            dissolves readily in liquids, making it ideal for instant food
            applications.
          </p>
          <h2>Applications</h2>
          <ul className="applications-ul">
            <li>Instant beverages (banana-flavored milk, smoothies)</li>
            <li>Instant baby food (quick-mix formulas)</li>
            <li>Ready-to-eat desserts (puddings, cakes, mousse)</li>
            <li>Dairy products (for banana-flavored yogurts and ice creams)</li>
            <li>Pre-packaged meals (for enhancing flavor and nutrition)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InstantBananaPowder;
