import React from "react";
import SubHeader from "../components/SubHeader";
import onionPowderImg from "../assets/images/onionPowder.jpeg";

const OnionPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="Onion Powder" />

      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "30px",
          marginBottom: "30px",
          flexWrap: "wrap",
        }}
        className="products-container products-text"
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-start",
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          <div style={{ flex: "2", maxWidth: "60%" }}>
            <h2>Description</h2>

            <p>
              Onion Powder is a versatile and convenient seasoning made from
              dehydrated onions that have been finely ground into a powder. It
              delivers the same rich, savory flavor as fresh onions, but with
              the added benefit of a long shelf life and no need for peeling or
              chopping.
            </p>

            <p>
              In addition to its culinary uses, onion powder also offers some
              health benefits. It contains compounds such as quercetin, which is
              known for its antioxidant properties and its ability to support
              immune health. Regular consumption of onion powder may help
              promote digestive health, reduce inflammation, and support heart
              health.
            </p>
          </div>

          <div style={{ flex: "1", textAlign: "center" }}>
            <img
              src={onionPowderImg}
              alt="Onion Powder"
              style={{
                maxWidth: "100%",
                borderRadius: "10px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </div>
        </div>
        <ul className="applications-ul">
          <h2>Benefits</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>Antioxidants:</strong> Protects cells from oxidative stress.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Anti-inflammatory:</strong> Reduces inflammation, helping
            with arthritis.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Immunity Boost:</strong> Supports the immune system.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Heart Health::</strong> : Helps regulate blood pressure and
            cholesterol.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Digestive Health:</strong> Promotes healthy gut bacteria.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Bone Health:</strong> Supports bone strength with calcium
            and vitamin C.
          </li>
        </ul>
        <ul className="applications-ul">
          <h2>Culinary Uses</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>Flavor Enhancer:</strong> Adds savory flavor to dishes.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Seasoning Blends:</strong> Key in taco and barbecue rubs.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Dairy-Free Creaminess:</strong> Enhances creamy textures.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Dry Rubs for Meat:</strong> Great for chicken, pork, and
            beef.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Baked Goods:</strong> CAdds flavor to savory breads and
            muffins.
          </li>
        </ul>

        <ul className="applications-ul">
          <h2>Non-Culinary Uses</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>DIY Skin Care:</strong> Antibacterial in masks and scrubs.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Pest Repellen:</strong>Repels insects in gardens.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Household Cleaner:</strong>Natural disinfectant for
            surfaces.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OnionPowder;
