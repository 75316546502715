import React from "react";
import SubHeader from "../components/SubHeader";

const DehydratedBananaPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="Dehydrated Banana Powder" />
      <div className="products-container">
        <div className="products-text">
          <h2>Description</h2>
          <p>
            Dehydrated banana powder is produced by drying bananas and grinding
            them into a fine powder, retaining their nutritional value.
          </p>
          <h2>Applications</h2>
          <ul className="applications-ul">
            <li>Baby food (for natural sweetness and easy digestion)</li>
            <li>Nutritional supplements (meal replacements, diet plans) </li>
            <li>
              Smoothies and protein shakes - Pet food (as a flavor enhancer)
            </li>
            <li>
              Healthy snacks and bars (used in various recipes for added flavor
              and nutrition)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DehydratedBananaPowder;
