import React, { useState } from "react";

const Sidebar = ({ setCurruntPath, menuItems, initName, styeside }) => {
  const [activePath, setActivePath] = useState(initName);

  const handlePathChange = (path) => {
    setCurruntPath(path);
    setActivePath(path);
  };

  return (
    <div id="sidebar">
      <div className="content widget-area clearfix">
        <aside
          id="nav_menu-8"
          className="widget-area widget_nav_menu"
          style={styeside}
        >
          <div className="menu-about-menu-container">
            <ul id="menu-about-menu" className="menu">
              {menuItems.map((item) => (
                <li key={item.name} style={{ cursor: "pointer" }}>
                  <a
                    style={
                      activePath === item.name
                        ? { borderLeft: "3px solid #712885" }
                        : {}
                    }
                    onClick={() => handlePathChange(item.name)}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default Sidebar;
