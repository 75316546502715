import React from "react";

const OurUsp = () => {
  return (
    <div className="about-us-container">
      <h2 className="about-title">Our Usp</h2>
      <p className="about-us-description">
        At Nexi Sphere Alliance, we offer more than just products — we deliver a
        comprehensive, trusted partnership in global trade. Our USP lies in our
        unmatched product quality, sustainable practices, and personalized
        service. Here's what sets us apart: Premium Natural Products: We
        specialize in high-quality natural exports like banana powder, ensuring
        that our products meet stringent global standards for quality and
        safety. Sustainability at Our Core: We are committed to ethical sourcing
        and environmentally responsible practices, ensuring that our business
        contributes positively to local communities and ecosystems. Tailored
        Trade Solutions: We provide customized export-import services, designed
        to meet the unique needs of our clients, from seamless logistics to
        reliable delivery schedules. Transparency & Trust: With clear
        communication and a commitment to integrity, we foster long-term,
        trust-based relationships with our partners and clients. Nexi Sphere
        Alliance is your gateway to a reliable, eco-conscious, and
        forward-thinking trade experience.
      </p>
    </div>
  );
};

export default OurUsp;
