import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import footerLogo from "../assets/images/footer-logo.png";
import { handleWhatsup, sendEmail } from "../pages/ContactPage";

export const windowScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const handleLinkdIn = () => {
  const webURL =
    "https://www.linkedin.com/company/nexispherealliance/?viewAsMember=true";
  window.open(webURL, "_blank");
};

const handleTwitter = () => {
  const webURL = "https://x.com/nexisphere";
  window.open(webURL, "_blank");
};

const Footer = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/contact-us" ? null : (
        <div className="footer-area-top">
          <div className="inner">
            <div className="at-callOutBox">
              <div className="at-callOut_inner">
                <div className="at-callOut-action">
                  <div className="at-callOut_text">
                    <div className="at-callout-action-text">
                      <h1
                        style={{
                          fontSize: "20px",
                          color: "black",
                          fontWeight: "500",
                          letterSpacing: "-1px",
                        }}
                      >
                        If You have any reqiurement of Nexi Sphere ... We Are
                        Available For Sourcing and supply
                      </h1>
                    </div>
                  </div>
                  <div className="at-callOut_btn" onClick={windowScroll}>
                    <div className="at-callout-action-button">
                      <NavLink
                        to={"contact-us"}
                        className="btn large"
                        style={{
                          color: "#black",
                          borderColor: "black",
                        }}
                        data-btn-color="#f0f0f0"
                      >
                        <span className="btn-text">CONTACT US </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="footer-area-middle">
        <div className="inner">
          <div className="inner-row">
            <aside id="text-4" className="whitetext widget widget_text col_3">
              <div className="textwidget">
                <p>
                  <img
                    src={footerLogo}
                    alt="Labora"
                    style={{
                      marginTop: "-20px",
                      marginBottom: "20px",
                      width: "170px",
                    }}
                  />
                </p>
                <h6 style={{ color: "#c6a9ce" }}>
                  Our dedication for quality and excellence has not only allowed
                  us to become leaders in the industry, it motivates us to
                  continuously innovate and improve on our products and
                  processes.
                </h6>
              </div>
            </aside>
            <div className="tp-foot-menu widget widget_nav_menu col_3">
              <h3>Our Product</h3>
              <div className="menu-footer-one-container">
                <ul className="footer-menu">
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/ripe-banana-powder">
                      • Ripe Banana Powder
                    </NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/green-banana-powder">
                      • Green Banana Powder (Plantain Flour)
                    </NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/organic-banana-powder">
                      • Organic Banana Powder
                    </NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/banana-flour">• Banana Flour</NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/spray-dried-banana-powder">
                      • Spray-Dried Banana Powder
                    </NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/freeze-dried-banana-powder">
                      • Freeze-Dried Banana Powder
                    </NavLink>
                  </li>{" "}
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/dehydrated-banana-powder">
                      • Dehydrated Banana Powder
                    </NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/instant-banana-powder">
                      • Instant Banana Powder
                    </NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/garlic-powder">• Garlic Powder</NavLink>
                  </li>{" "}
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/onion-powder">• Onion Powder</NavLink>
                  </li>{" "}
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/moringa-powder">• Moringa Powder</NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/peanut-powder">• Peanut Powder</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tp-foot-menu widget widget_nav_menu col_3">
              <h3>Quick Links</h3>
              <div className="menu-footer-one-container">
                <ul className="footer-menu">
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/about-us"> • About Us</NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/ripe-banana-powder"> • Products</NavLink>
                  </li>
                  <li onClick={() => windowScroll()}>
                    <NavLink to="/contact-us"> • Contact Us</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tp-foot-menu widget widget_nav_menu col_3">
              <h3>Get in Touch</h3>
              <div className="contactinfo-wrap">
                <p className="phone">
                  <span className="icon fa fa-phone fa-lg"></span>
                  <NavLink className="details">(+91) 89807 07565</NavLink>
                </p>
                <p
                  className="phone"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <span className="icon fa fa-regular fa-envelope"></span>
                  <span className="details">
                    <NavLink onClick={sendEmail}>
                      connect@nexispherealliance.com
                    </NavLink>
                  </span>
                </p>

                <p>
                  <span className="fa-brands fa-whatsapp fa-lg icon"></span>
                  <span className="details">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={handleWhatsup}
                    >
                      WhatsApp
                    </NavLink>
                  </span>
                </p>
                <p>
                  <span className="fa-brands fa-linkedin fa-lg icon"></span>
                  <span className="details">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={handleLinkdIn}
                    >
                      Linkedin
                    </NavLink>
                  </span>
                </p>
                <p>
                  <span className="fa-brands fa-twitter fa-lg icon"></span>
                  <span className="details">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      onClick={handleTwitter}
                    >
                      Twitter
                    </NavLink>
                  </span>
                </p>
                <p>
                  <span className="icon fa fa-brands fa-instagram fa-lg"></span>
                  <span className="details">
                    <NavLink
                      to={
                        "https://www.instagram.com/nexispherealliance/"
                      }
                      target="_blank"
                    >
                      Instagram
                    </NavLink>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
