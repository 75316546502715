import React from "react";
import SubHeader from "../components/SubHeader";

const SprayDriedBananaPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="SprayDried Banana Powder" />
      <div className="products-container">
        <div className="products-text">
          <h2>Description</h2>
          <p>
            Created using advanced spray-drying technology, this powder has a
            long shelf life and retains the full flavor of fresh bananas.
          </p>
          <h2>Applications</h2>
          <ul className="applications-ul">
            <li>Beverage mixes (instant banana milkshakes, flavored drinks)</li>
            <li>Dairy products (ice creams, yogurt)</li>
            <li>Confectioneries (candies, fillings)</li>
            <li>Snacks (banana chips, health bars)</li>
            <li>Bakery products (cakes, biscuits)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SprayDriedBananaPowder;
