import React from "react";
import SubHeader from "../components/SubHeader";

const BananaFlour = () => {
  return (
    <>
      <SubHeader subHeaderText="Banana Flour" />
      <div className="products-container">
        <div className="products-text">
          <h2>Description</h2>
          <p>
            A fine powder made from dried bananas, banana flour is a popular
            substitute for wheat flour, especially in gluten-free and paleo
            diets.
          </p>
          <h2>Applications</h2>
          <ul className="applications-ul">
            <li>Gluten-free baked goods (bread, cakes, pastries)</li>
            <li>Thickening agent (soups, stews, sauces)</li>
            <li>Pasta and noodle production</li>
            <li>Baby food and toddler snacks</li>
            <li>Smoothies and protein shakes</li>
            <li>Pet food (as a natural ingredient for dog treats)</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default BananaFlour;
