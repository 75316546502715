import React from "react";
import { Link } from "react-router-dom";

const SubHeader = ({ subHeaderText }) => {
  return (
    <div className="tp-about-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="tb-breadcrumb-content">
              <h2>{subHeaderText}</h2>
              <div className="tb-breadcrumb-list ul-li">
                <ul>
                  <li>
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>

                  <i
                    className="fa fa-angle-double-right"
                    aria-hidden="true"
                  ></i>
                  <li>{subHeaderText}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
