import React from "react";

const OurStory = () => {
  return (
    <div className="about-us-container">
      <h2 className="about-title">Our Story</h2>
      <p className="about-us-description">
        At Nexi Sphere Alliance, trust is the cornerstone of our business.
        Founded by Akash Sheladiya and Vishwesh Kasodariya , our mission is to
        make global trade transparent, reliable, and ethically driven. We
        recognized the need for a partner who could deliver quality products and
        peace of mind. What sets us apart is our commitment to honesty,
        sustainability, and reliability. We ensure our banana powder and other
        natural products meet the highest standards while supporting the
        communities we source from. Clients trust Nexi Sphere Alliance because
        we keep our promises. From clear communication to on-time delivery, we
        build relationships based on transparency. In a world where trust can be
        elusive, we consistently deliver on our commitments, ensuring that when
        you partner with us, you’re choosing integrity, quality, and long-term
        success.
      </p>
    </div>
  );
};

export default OurStory;
