import React from "react";
import SubHeader from "../components/SubHeader";

const GreenBananaPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="Green Banana Powder" />
      <div className="products-container">
        <div className="products-text">
          <h2>Description</h2>
          <p>
            Processed from unripe green bananas, this powder is rich in
            resistant starch, making it ideal for gluten-free and
            health-conscious consumers.
          </p>
          <h2>Applications</h2>
          <ul className="applications-ul">
            <li>Gluten-free baking (bread, cakes, cookies)</li>
            <li>
              Nutritional supplements (for digestive health and weight
              management)
            </li>
            <li>Thickening agent in soups and sauces</li>
            <li>Pasta and noodles (as a gluten-free ingredient)</li>
            <li> Baby food (as a thickener and nutritious alternative)</li>
            <li>Smoothies and health drinks (for added fiber and nutrition)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GreenBananaPowder;
