import React from "react";
import SubHeader from "../components/SubHeader";
import moringaPowderImg from "../assets/images/moringaPowder.jpeg";

const MoringaPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="Moringa Powder" />
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "30px",
          marginBottom: "30px",
          flexWrap: "wrap",
        }}
        className="products-container products-text"
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-start",
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          <div style={{ flex: "2", maxWidth: "60%" }}>
            <h2>Description</h2>

            <p>
              Moringa Powder is made from the leaves of the Moringa oleifera
              tree, often referred to as the "Miracle Tree" due to its numerous
              health benefits. The leaves are carefully dried and ground into a
              fine powder, which is packed with essential nutrients, vitamins,
              and minerals. Moringa powder is rich in antioxidants,
              anti-inflammatory compounds, and has a variety of beneficial
              properties, making it a popular addition to smoothies, teas,
              soups, and other dishes.
            </p>

            <p>
              This superfood is known for its high content of vitamins A, C, and
              E, which support immune health, improve skin health, and reduce
              oxidative stress. Moringa powder also contains essential minerals
              like calcium, potassium, and iron, making it an excellent
              supplement for overall health. Additionally, it is a great source
              of protein and amino acids, promoting muscle health and energy
              levels.
            </p>
          </div>

          <div style={{ flex: "1", textAlign: "center" }}>
            <img
              src={moringaPowderImg}
              alt="Moringa Powder"
              style={{
                maxWidth: "100%",
                borderRadius: "10px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </div>
        </div>
        <ul className="applications-ul">
          <h2>Benefits</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>Boosts Immunity:</strong> High in vitamin C and
            antioxidants.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Improves Digestion:</strong> Aids digestion and prevents
            constipation.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Enhances Bone Health:</strong> Contains calcium and
            phosphorus.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Regulates Blood Sugar:</strong> Helps stabilize blood sugar
            levels.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Supports Heart Health:</strong> Lowers cholesterol and
            reduces heart disease risk.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Aids Weight Management:</strong> High in fiber, promotes
            satiety.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Anti-inflammatory:</strong> Reduces inflammation, easing
            joint pain.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Antioxidant Power:</strong> Protects cells from free radical
            damage.
          </li>
        </ul>
        <ul className="applications-ul">
          <h2> Uses</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>Dietary Supplement:</strong> Add to smoothies, juices, or
            yogurt.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Culinary Ingredient:</strong> Flavor curries, soups, and
            stews.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Skincare:</strong> Soothes skin irritations.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Hair Care:</strong> Strengthens hair and prevents hair loss.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MoringaPowder;
