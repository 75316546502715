import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ContactPage from "../pages/ContactPage";
import Aboutpage from "../pages/AboutPage/AboutPage";
import RipeBananaPowder from "../pages/RipeBananaPowder";
import GreenBananaPowder from "../pages/GreenBananaPowder";
import OrganicBananaPowder from "../pages/OrganicBananaPowder";
import SprayDriedBananaPowder from "../pages/SprayDriedBananaPowder";
import FreezeDriedBananaPowder from "../pages/FreezeDriedBananaPowder";
import DehydratedBananaPowder from "../pages/DehydratedBananaPowder";
import InstantBananaPowder from "../pages/InstantBananaPowder";
import BananaFlour from "../pages/BananaFlour";
import GarlicPowder from "../pages/GarlicPowder";
import OnionPowder from "../pages/OnionPowder";
import MoringaPowder from "../pages/MoringaPowder";
import PeanutButter from "../pages/PeanutButter";

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/about-us" element={<Aboutpage />} />
      <Route path="/ripe-banana-powder" element={<RipeBananaPowder />} />
      <Route path="/green-banana-powder" element={<GreenBananaPowder />} />
      <Route path="/organic-banana-powder" element={<OrganicBananaPowder />} />
      <Route
        path="/spray-dried-banana-powder"
        element={<SprayDriedBananaPowder />}
      />
      <Route path="/banana-flour" element={<BananaFlour />} />
      <Route
        path="/freeze-dried-banana-powder"
        element={<FreezeDriedBananaPowder />}
      />
      <Route
        path="/dehydrated-banana-powder"
        element={<DehydratedBananaPowder />}
      />
      <Route path="/instant-banana-powder" element={<InstantBananaPowder />} />
      <Route path="/garlic-powder" element={<GarlicPowder />} />
      <Route path="/onion-powder" element={<OnionPowder />} />
      <Route path="/moringa-powder" element={<MoringaPowder />} />
      <Route path="/peanut-butter" element={<PeanutButter />} />
    </Routes>
  );
};

export default RoutesComponent;
