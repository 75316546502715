import React from "react";
import SubHeader from "../components/SubHeader";

const RipeBananaPowder = () => {
  return (
    <>
      <SubHeader subHeaderText="Ripe Banana Powder" />
      <div className="products-container">
        <div className="products-text">
          <h2>Description</h2>
          <p>
            Made from fully ripened bananas, this powder retains the natural
            sweetness and flavor of the fruit. It is highly versatile and can be
            used across several industries.
          </p>
          <h2>Applications</h2>
          <ul className="applications-ul">
            <li>Baby food (as a natural sweetener and thickener) </li>
            <li>smoothies and beverages - Baking (cakes, muffins, pancakes)</li>
            <li>Dairy products (ice creams, yogurts, flavored milk)</li>
            <li>Nutritional supplements (energy bars, protein shakes)</li>
            <li> Confectioneries (candies, chocolates)</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default RipeBananaPowder;
