import React from "react";
import SubHeader from "../components/SubHeader";
import peanutButterImg from "../assets/images/peanutButter.jpeg";

const PeanutButter = () => {
  return (
    <div>
      <SubHeader subHeaderText="Peanut Butter" />
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "30px",
          marginBottom: "30px",
          flexWrap: "wrap",
        }}
        className="products-container products-text"
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-start",
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          <div style={{ flex: "2", maxWidth: "60%" }}>
            <h2>Description</h2>

            <p>
              Peanut Butter is a creamy or crunchy spread made from ground
              peanuts, offering a rich and nutty flavor. It’s a popular snack
              and ingredient in a wide range of dishes, from sandwiches to
              smoothies, cookies, and sauces. Peanut butter is packed with
              protein, healthy fats, and essential nutrients like vitamins E and
              B, magnesium, and potassium, making it a great addition to a
              balanced diet.
            </p>

            <p>
              In addition to its nutritional value, peanut butter also offers
              several health benefits. The healthy fats found in peanut butter,
              particularly monounsaturated fats, can help support heart health
              by lowering bad cholesterol levels. It's also known to aid in
              weight management by promoting feelings of fullness due to its
              high protein and fiber content.
            </p>
          </div>

          <div style={{ flex: "1", textAlign: "center" }}>
            <img
              src={peanutButterImg}
              alt="Moringa Powder"
              style={{
                maxWidth: "100%",
                borderRadius: "10px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </div>
        </div>
        <ul className="applications-ul">
          <h2>Benefits</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>Nutrient-Rich:</strong> High in protein, fiber, vitamins
            (especially E), and minerals like magnesium.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Heart Health:</strong> Unsaturated fats help lower bad
            cholesterol (LDL) and increase good cholesterol (HDL).
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Weight Management:</strong> Protein and fiber keep you full
            longer, reducing cravings.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Blood Sugar Control:</strong> Slows sugar absorption,
            preventing blood sugar spikes.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Boosts Energy:</strong> Provides sustained energy from
            healthy fats and carbs.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Antioxidant Properties:</strong> Contains resveratrol,
            protecting cells from damage.
          </li>
        </ul>
        <ul className="applications-ul">
          <h2> Uses</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>Breakfast:</strong> Spread on toast, mix into oatmeal, or
            use in sandwiches.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Lunch:</strong> Add to wraps or use as a dip for
            fruits/vegetables.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Snacks:</strong> Pair with crackers or apples; make energy
            balls or protein bars.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Dinner:</strong> Enhance stir-fries, curries, or use as a
            marinade.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Dessert:</strong> Make cookies, brownies, or add to
            smoothies and ice cream.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PeanutButter;
