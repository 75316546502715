import React from "react";
import SubHeader from "../components/SubHeader";
import garlicPowderImg from "../assets/images/garlicPowder.jpeg";

const GarlicPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="Garlic Powder" />

      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          gap: "30px",
          marginBottom: "30px",
          flexWrap: "wrap",
        }}
        className="products-container products-text"
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-start",
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          <div style={{ flex: "2", maxWidth: "60%" }}>
            <h2>Description</h2>

            <p>
              Garlic Powder is a convenient and flavorful alternative to fresh
              garlic. Made by dehydrating and finely grinding garlic cloves, it
              offers the same bold taste with minimal preparation. Its
              versatility makes it a favorite ingredient in a variety of dishes,
              including soups, sauces, marinades, and seasoning blends. Garlic
              powder is especially appreciated for its long shelf life and ease
              of use, as it eliminates the need for peeling and chopping fresh
              garlic.
            </p>

            <p>
              In addition to enhancing the flavor of food, garlic powder is
              packed with health benefits. It contains sulfur compounds, such as
              allicin, known for their antibacterial, antifungal, and antiviral
              properties. Regular consumption can support heart health, boost
              immunity, improve digestion, and reduce inflammation. It's a
              simple yet powerful ingredient to incorporate into your daily
              meals for both taste and wellness.
            </p>
          </div>

          <div style={{ flex: "1", textAlign: "center" }}>
            <img
              src={garlicPowderImg}
              alt="Garlic Powder"
              style={{
                maxWidth: "100%",
                borderRadius: "10px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </div>
        </div>

        <ul className="applications-ul">
          <h2>Benefits</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>Heart Health:</strong> Garlic powder can help lower blood
            pressure and cholesterol, promoting cardiovascular health.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Antioxidant-Rich:</strong> Supports immunity and reduces
            inflammation.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Antimicrobial:</strong> Known to fight bacteria, fungi, and
            viruses, contributing to a stronger immune response.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Digestive Aid:</strong> Balances gut bacteria for better
            digestion.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Anti-inflammatory:</strong> Helps reduce inflammation,
            potentially easing symptoms of conditions like arthritis.
          </li>
        </ul>

        <ul className="applications-ul">
          <h2>Uses</h2>
          <li style={{ alignItems: "baseline" }}>
            <strong>Cooking:</strong> Adds flavor to dishes like soups, sauces,
            marinades, and stir-fries without the hassle of peeling and chopping
            fresh garlic.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Seasoning Blends:</strong> Mixes with other spices for
            customized blends.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Health Tonic:</strong> Add to warm water with lemon for
            immunity boost.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Snacks:</strong> A great addition to popcorn, nuts, or
            roasted chickpeas for a savory flavor boost.
          </li>
          <li style={{ alignItems: "baseline" }}>
            <strong>Homemade Spice Mixes:</strong> Combines well with spices
            like paprika, cumin, and onion powder for custom seasoning mixes.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GarlicPowder;
