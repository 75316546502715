import React from "react";

const OurVision = () => {
  return (
    <div className="about-us-container">
      <h2 className="about-title">Our Vision</h2>
      <p className="about-us-description">
        At Nexi Sphere Alliance, our vision is to be a leading force in the
        global industry, recognized for our commitment to excellence,
        sustainability and innovation. We want to tap into international
        markets, create seamless business experiences that empower businesses
        and provide consumers with high-quality, natural products. We envision a
        future where global trade not only drives economic growth, but also
        contributes to the well-being of local communities and the environment.
        By constantly embracing new opportunities, we aim to set new standards
        for ethical and fair business practices, ensuring that our partners and
        customers thrive in a connected world.
      </p>
    </div>
  );
};

export default OurVision;
