import React from "react";

const OurMission = () => {
  return (
    <div className="about-us-container">
      <h2 className="about-title">Our Mission</h2>
      <p className="about-us-description">
        At Nexi Sphere Alliance, our venture is to attach international markets
        with terrific merchandise whilst fostering sustainable and transparent
        trade practices. We believe in the power of collaboration, innovation,
        and trust, operating closely with our partners and clients to ensure the
        smooth flow of products across borders. Our core focus is to provide
        great export-import solutions, with an emphasis on natural products.
        We are committed to delivering top-tier products that meet international
        standards while also supporting local communities and promoting
        environmentally responsible practices. Together, we aim to build
        long-lasting relationships that benefit businesses and clients alike,
        ensuring mutual growth and success in a dynamic global market.
      </p>
    </div>
  );
};

export default OurMission;
