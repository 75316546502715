import React from "react";
import SubHeader from "../components/SubHeader";

const FreezeDriedBananaPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="FreezeDrie Banana Powder" />
      <div className="products-container">
        <div className="products-text">
          <h2>Description</h2>
          <p>
            Freeze-dried to retain the maximum amount of nutrients and flavor,
            this powder is often used in premium products.
          </p>
          <h2>Applications</h2>
          <ul className="applications-ul">
            <li>Premium snacks (smoothie bowls, granola)</li>
            <li>High-end cosmetics (used for skin nourishment)</li>
            <li>Nutritional supplements (high-energy and protein bars)</li>
            <li>Baby food and infant formula</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FreezeDriedBananaPowder;
