import React from "react";
import SubHeader from "../components/SubHeader";

const OrganicBananaPowder = () => {
  return (
    <div>
      <SubHeader subHeaderText="Origanic Banana Powder" />
      <div className="products-container">
        <div className="products-text">
          <h2>Description</h2>
          <p>
            This premium banana powder is made from organically grown bananas,
            free from pesticides and synthetic chemicals, ensuring a
            high-quality, natural product.
          </p>
          <h2>Applications</h2>
          <ul className="applications-ul">
            <li>Organic baby food (as a base or natural sweetener)</li>
            <li>Organic beverages (smoothies, health drinks)</li>
            <li>Organic snacks (energy bars, organic confectionery)</li>
            <li>
              Organic cosmetics (used in face masks and scrubs for natural
              exfoliation)
            </li>
            <li>Natural supplements (for vitamins and minerals)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrganicBananaPowder;
